"use client"

import { useState } from "react"
import Image from "next/image"
import axios from "axios"
import { event } from "nextjs-google-analytics"
import PhoneInput from "react-phone-number-input"
import { toast } from "sonner"

import { Card, CardContent, CardHeader } from "@/components/ui/card"

import { Button } from "./ui/button"
import { Input } from "./ui/input"

const MyIcon = () => (
  <div className="relative">
    <Image
      className="absolute top-[-3px] object-fill object-bottom h-5 w-5"
      src="/assets/images/globe-logo-dark.png"
      alt="abstract art"
    />
  </div>
)

const CallToAction = () => {
  const [subscriberPhone, setSubscriberPhone] = useState<string>("")

  const storeSubscriberPhone = async () => {
    if (subscriberPhone === "") toast.error("Please enter a valid phone number")

    const res = await axios.post("/api/movement", {
      phone: subscriberPhone,
    })

    event("join_alien_wardrobe_movement", {
      category: "phone",
      label: subscriberPhone,
    })

    if (res.status === 201) toast.success("Thanks for subscribing 🎉")
    else toast.error("Something went wrong. Please try later.")

    setSubscriberPhone("")
  }

  return (
    <section className="px-2 md:container">
      <Card className="w-full mt-20 relative h-full p-5 md:p-10 rounded-[2rem] text-center shadow-lg border-none text-gray-200 bg-gradient-to-br from-green-700 to-green-900">
        <Image
          src="/assets/images/art.png"
          fill
          alt="abstract art"
          className="object-cover rounded-2xl opacity-10"
        />
        <CardHeader className="z-1 px-0 ">
          <h2 className="text-[14px] md:text-[32px] mb-5">
            Join our{" "}
            <span className="text-[20px] md:text-[40px] font-semibold">
              Alien Wardrobe Movement
            </span>
          </h2>
          <p className="text-[12px] md:text-[16px] font-normal">
            Unlock exclusive perks including early access to new arrivals, and
            subscriber-only discounts.
          </p>
        </CardHeader>
        <CardContent className="flex flex-col items-center relative z-1">
          {/*<Input
            type="email"
            className="rounded-full my-5 block md:w-[30%] w-full ps-5 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
            placeholder="Email Id"
            required
            onChange={(e) => setSubscriberPhone(e.target.value)}
            value={subscriberPhone}
          />*/}
          <PhoneInput
            className="rounded-full mb-5 block md:w-[30%] w-full ps-5 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-gray-500 dark:focus:ring-gray-500"
            placeholder="Enter phone number"
            //defaultCountry="IN"
            internationalIcon={MyIcon}
            onChange={(value: string | undefined) =>
              setSubscriberPhone(value as string)
            }
            value={subscriberPhone}
          />
          <Button
            type="button"
            variant="outline"
            onClick={storeSubscriberPhone}
            className="rounded-full shadow-lg bg-white text-xs px-8 py-2 font-semibold text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Subscribe
          </Button>
        </CardContent>
      </Card>
    </section>
  )
}

export default CallToAction
